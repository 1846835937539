<template>
  <v-row class="d-flex justify-center">
    <v-col cols="12" sm="8" md="8" lg="6" xl="4">
      <v-form>
        <v-card class="xs-margin xs-padding sm-padding card-shadow bg-white">
          <h1 class="text-h1 font-weight-600 text-black text-center mb-2 pt-5">
            Welcome to GoDigy!
          </h1>
          <p
            class="text-black font-size-root text-center font-weight-thin mb-2"
          >
            We help you optimise your business processes by allowing you to create and share custom designed forms that enhances collaboration and efficiency.
          </p>
          <v-card-text class="font-size-root">
            <div class="illustration-img">
              <img :src="illustration" :alt="illustration" />
            </div>
            <v-btn
              to="onboarding"
              elevation="0"
              block
              
              height="43"
              class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm mt-6"
              color="#6F70FF"
            >
              Create new account
            </v-btn>
          </v-card-text>
        </v-card>
      </v-form>

      <div class="d-flex justify-center mt-4">
        <small class="text-light">Already have an Account?
          <router-link
            to="login"
            class="text-decoration-none btn-hover no-default-hover"
          >
            <span class="text-primary">Sign in.</span>
          </router-link>
        </small>
      </div>
    </v-col>
  </v-row>
</template>
<script>


export default {
  name: "createAccount",
  data() {
    return {
      checkbox: false,
      showPassword: false,
      illustration: require("@/assets/img/illustrations/undraw_Collaboration_re_vyau.svg")
    };
  }
};
</script>

<style lang="scss" scoped>
.illustration-img {
  display: flex;
  justify-content: center;

  img {
    height: 15rem;
  }
  
}

@media screen and (max-width: 599px) {
    .xs-margin {
        margin: 0 10px;
    }

    .xs-padding {
        padding: 10px;
    }
}

@media screen and (min-width: 600px) {
    .sm-padding {
        padding: 40px;
    }
}
</style>
